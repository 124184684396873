<template>
  <div class="success-container">
    <div class="success-message">
      <h2>Вітаємо з успішною реєстрацією! 🎉</h2>
      <p>
        Для завершення реєстрації, будь ласка, перевірте свою електронну пошту та підтвердіть свій обліковий запис, натиснувши на посилання у надісланому листі.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessMessage",
};
</script>

<style scoped>
.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  z-index:10000;
}

.success-message {
  max-width: 600px;
  width: 100%;
  text-align: center;
  padding: 2.5rem;
  border-radius: 12px;
  background: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border:1px solid #43B6F5;
}

h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  line-height: 1.3;
}

p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
</style>
