<template>
  <div class="feedback">
    <div class="feedback__container">
      <div class="feedback__column">
        <h3 class="feedback__title">Ми на зв’язку і завжди готові допомогти</h3>
        <div class="feedback__time">
          <p>У будні з 9:00 до 18:00</p>
          <p>У вихідні з 9:00 до 13:00</p>
        </div>
        <div class="feedback__tel">
          <p>Гаряча лінія</p>
          <p>+ 38 066 434 30 28</p>
        </div>
        <div class="feedback__email">
          <p>Електронна пошта</p>
          <p>spd@proconnect.construction</p>
        </div>
      </div>

      <div class="feedback__column">

        <form @submit.prevent="sentFeedback" class="feedback__column__form form" action="">
          <div class="form__input">
            <PlaceholderTextInput
                :modelValue="formData.name = this.user.full_name"
                class="form__input__text"
                type="text"
                placeholder="Ім'я*"
                @update:modelValue="formData.name = $event"
            />
            <PlaceholderTextInput
                :modelValue="formData.email || ''"
                class="form__input__email"
                type="email"
                placeholder="Email*"
                @update:modelValue="formData.email = $event"
            />
            <PlaceholderTextInput
                :modelValue="formData.phone || ''"
                class="form__input__tel"
                type="tel"
                placeholder="Номер телефону"
                @update:modelValue="formData.phone = $event"
            />

          </div>
          <div   style="background: white;">
            <Dropdown
              :list="listTopic"
              @select="onTopicSelect"
              :hasSubGroups="false"
              :multipleChoice="false"
              placeholder="Оберіть тему звернення*"
              :modelValue="getTopic"
              input-height="40px"
          />
          </div>

          <PlaceholderTextInput
              :modelValue="formData.message || ''"
              class="form__input__textarea"
              typeTag="textarea"
              type="textarea"
              placeholder="Повідомлення*"
              @update:modelValue="formData.message = $event"

          />
          <div class="form__select">
            <div class="form__select checkbox">
              <CheckboxInput
                  v-model="formData.agreement"
                  class="checkbox__input"
                  required
                  type="checkbox"/>
              <label
                  class="checkbox__label"
                  for="agree">
                <span class="checkbox__text">Я ознайомився з
                <a href="/privacy">Політикою конфіденційності</a>
              Pro Connect i даю згоду на використання моїх даних відповідно до неї</span></label>
            </div>

            <Button
                type="submit"
                class="form__select__button "
            >Надіслати
            </Button>
            <div
                v-if="this.successfulSending === true"
                class='message'
            >
              <p>Ваше повідомлення успішно надіслано!</p>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

</template>
<script>


import Button from "@/components/UI/Button.vue";
import Dropdown from "@/components/Dropdown.vue";
import $api from "@/http";

export default {
  name: "FeedBack",
  components: {Dropdown, Button},
  data: () => ({
    formData: {
      name: null,
      email: null,
      phone: null,
      message: null,
      topic_id: null,
      agreement: false,
    },
    successfulSending: false,
    isPopupVisible: "false",
  }),
  methods: {
    async sentFeedback() {
      if (this.hasError()) return;
      try {
        const response = await $api.post('/users/support-email/', { ...this.formData, topic_id: this.formData.topic_id[0] });
        console.log("Feedback submitted to API", response);
        this.clearFormData();
        this.successfulSending = true;
      } catch (error) {
        console.error("Error sending feedback:", error);
        console.log(error.response);
      }
    },
    clearFormData() {
      this.formData = {
        name: '',
        email: '',
        phone: '',
        message: '',
        topic_id: null,
        agreement: false
      };
    },
    hasError(field = undefined) {
      if (field === undefined) {
        for (let key in this.formData) {
          if (!this.formData[key]) {
            return true;
          }
        }
        return false;
      } else {
        return !this.formData[field];
      }
    },
    onTopicSelect(e) {
      this.formData.topic_id = [e];
    },

  },
  computed: {
    getTopic() {
      if (!this.listTopic || !this.formData.topic_id) return "";
      let result = this.listTopic.find((type) => type.id === this.formData.topic_id[0]);
      return result ? result.title : "";
    },
  }
}


</script>


<style lang="scss">
.feedback {
  max-width: 1100px;
  margin: 0 auto;
  padding: 15px;
  @media (max-width: 768px){
    padding: 25px;
  }
  &__container {
    margin-top: 140px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    @media (max-width: 768px){
      margin-top: 10px;
      gap: 25px;
      flex-direction: column;
    }
  }

  &__column {
    flex: 0 1 50%;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 16px;
    @media (max-width: 768px){
      font-size: 26px;
    }
    @media (max-width: 375px){
      font-size: 20px;
    }
  }
  &__time{
    margin-bottom: 40px;
    font-weight: 500;
    position: relative;
    @media (max-width: 768px){
      margin-bottom: 15px;
    }

    & p {
      display: inline;

    }
    & p:first-child {
      margin-right: 32px;

    }
    & p:first-child:after {
      content: "";
      position: absolute;
      left: 191px;
      height: 20px;
      width: 1.5px;
      background-position: center center;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAgCAYAAAArBentAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHBSURBVHgBjVSxcsIwDLWSFkh77cbAHRPHBCMf0HTp2KXlf8LQfgx8RT+AgePCUuianYWFWH0ytrEDd63uRBzlWU96MlYKxswkLuvdd1WIu7iylsgPkX8PDWHiCCibG++qKArZ7TOkjmI2m1G326Wn/OUZAXp7f/0K03vqsixpsVikWms6wm0Sz3DjFgCdqABiNsAkKMnvoMlkYj7WJ5Dq9XrJaDTydSaqaZpJw6uqisKOmpfLpcmsbQAZ1WaziZuxUpiPiQ2macqgjtlEHtR4i+Vduf75WK92n7KGt5yEJqNoCGoKu1RnsTmkNgFQQ0OOAFHXFmg6lYBrSGrEN45qxMPUKPWt4MguNbZdsqRRk98t2ZGRIx1l1kKz3+9VuDvLshgoBnHDjo0dDof44AZn7+oJjrqG4CKP7/gqUJ1mfXFKZNZWuvMxU39YdMxEkpB6OBxSMyM7D3dut1t2QGWbke8tO41HcVlD21aT1QDhGfxBvN/vZxdAmXWe5yJ+B35vXda3zYzm72kzdGzmtmx214pHT6dT0U0DrCH+EaEaFwK76yax1DyfzzUeNcD1YDAQlfR4PI7nH9xa4cwpvM3+bb9OtMcXEF1yfAAAAABJRU5ErkJggg==");

    }

  }
  &__tel{
    margin-bottom: 40px;
    @media (max-width: 768px){
      margin-bottom: 15px;
    }
    p:nth-child(1) {
      margin-bottom: 4px;
      font-weight: 500;
    }

    p:nth-child(2) {
      font-size: 24px;
      font-weight: 700;
      @media (max-width: 768px){
        font-size: 20px;
      }
      @media (max-width: 375px){
        font-size: 16px;
      }
    }
  }
  &__email{
    p:nth-child(1) {
      margin-bottom: 4px;
      font-weight: 500;
    }

    p:nth-child(2) {
      font-weight: 700;
      font-size: 24px;
      @media (max-width: 768px){
        font-size: 20px;
      }
      @media (max-width: 375px){
        font-size: 16px;
      }

    }
  }
  &__time,
  &__tel,
  &__email {
    margin-bottom: 10px;
  }
}

.form {
  &__input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-bottom: 16px;
    input {
      padding: 10px 15px;
      border-radius: 8px;
    }
    &__text {
      grid-area: 1 / 1 / 2 / 3;
      height:40px ;
    }

    &__email {
      grid-area: 2 / 1 / 3 / 2;
      height:40px ;
    }

    &__tel {
      grid-area: 2 / 2 / 3 / 3;
      height:40px ;
    }

    &__textarea {
      margin-top: 16px;
    }
  }

  &__select {
    margin-top: 20px;

    .checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      width: 100%;
      margin-bottom: 32px;

      &__label {
        margin-left: 10px;
      }

      &__text a {
        color: #4EB2FF;
        text-decoration: underline;
      }
    }

    &__button {
      margin-top: 10px;
    }
  }
}
.message {
  text-align: center;
  color: #4EB2FF;
  margin-top: 15px;
}
.dropdown .inputSearch {
  height: 40px !important;
}
</style>